import Layout from "@components/layout";

import React, { useEffect, useState } from "react";
import {
  withMKBList,
  withMKBPlayers,
  withMKBAction,
} from "@helpers/hoc/withQueries";
import { Toast } from "antd-mobile";

import {
  withMutateAddMatch,
  withMutateAddCredit,
  withMutatePatchPages,
} from "@helpers/hoc/withMutations";
import { AppOutline } from "antd-mobile-icons";
import SEO from "@components/common/seo";
// import { InstallPWAButton } from "@components/common/pwaButton";
import {
  QuestionCircleOutlined,
  TrophyOutlined,
  RiseOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import "@styles/global.styles.css";
import {
  Select,
  Form,
  Button,
  DatePicker,
  Divider,
  Input,
  Tag,
  Modal,
  Spin,
  Skeleton,
  Popconfirm,
  Empty, // Import the Empty component
} from "antd";
import dayjs from "dayjs";
import {
  extractGuestData,
  sortByDate,
  getTodaysData,
  calculateTotalPoints,
  isBrowser,
} from "@helpers/tools";
import { useFormContext } from "@helpers/hooks/formContext"; // Adjust the import path
import { navigate } from "gatsby";

type Props = {
  location?: {
    pathname: string;
  };
  mkbList: {
    notionMKBList: any;
  };
  mkbPlayers: {
    notionMKBPlayers: any;
  };
  matchData: any;
  creditCreate: Function;
  refetchNotionMKBList: Function;
  patchPages: Function;
  mkbAction: any;
  loadingMkbPlayers: boolean;
  loadingMKBList: boolean;
};

const Home: React.FC<Props> = ({
  location,
  mkbList,
  mkbPlayers,
  refetchNotionMKBList,
  matchData,
  mkbAction,
  creditCreate,
  loadingMKBList,
  loadingMkbPlayers,
  patchPages,
}) => {
  const username = isBrowser() && localStorage.getItem("username");
  const [form2] = Form.useForm();
  const [open, setOpen] = useState(false);

  const select = extractGuestData(
    mkbPlayers?.notionMKBPlayers?.data?.results
  )?.sort((a, b) => b.handicap - a.handicap);
  const selectAction = extractGuestData(mkbAction?.mkbAction?.data?.results);

  const list = sortByDate(mkbList?.notionMKBList?.data?.results);

  const todayMatches = getTodaysData(list);
  const todayPool = calculateTotalPoints(todayMatches, select)?.sort(
    (a, b) => b.totalPoints - a.totalPoints
  );

  // Calculate total points for the day
  const totalPoints =
    todayPool?.reduce((acc, res) => acc + res.totalPoints, 0) || 0;

  useEffect(() => {
    refetchNotionMKBList();
  }, [matchData]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!loadingMKBList) {
      setIsLoading(false);
    }
  }, [loadingMKBList]);
  const { setFormValues } = useFormContext();
  return (
    <Layout location={location.pathname}>
      <SEO title="Badminton" />

      <div className="mt-4">
        <Skeleton loading={isLoading} active>
          <div>
            <Spin tip="Loading" size="large" spinning={loadingMKBList}>
              {username && (
                <div className="ml-7 text-primary-100 font-semibold">
                  Welcome, {username.toUpperCase()}!
                </div>
              )}
              {/* <InstallPWAButton /> */}
              <Divider orientation="left">
                Today&apos;s Matches <TrophyOutlined />
              </Divider>
              <div className="m-2">
                {todayMatches && todayMatches.length > 0 ? (
                  todayMatches.map((get, num) => {
                    const loserS = get.properties["Loser Score"].number;

                    const handleMatchClick = match => {
                      if (setFormValues) {
                        setFormValues({
                          wp1: match.properties.PW1.relation[0].id,
                          wp2: match.properties.PW2.relation[0].id,
                          lp1: match.properties.PL1.relation[0].id,
                          lp2: match.properties.PL2.relation[0].id,
                        });
                        navigate("/");
                      } else {
                        console.error("setFormValues is not available");
                      }
                    };

                    // Calculate the reverse index
                    const reverseIndex = todayMatches.length - num;

                    return (
                      <div key={num}>
                        <div
                          className="mb-1 cursor-pointer"
                          onClick={() => handleMatchClick(get)}
                        >
                          Match {reverseIndex}:{" "}
                          {/* Display the reverse index */}
                        </div>
                        <Tag color="geekblue">
                          {loserS > 21 ? loserS + 2 : 21}-{loserS}
                        </Tag>
                        <Popconfirm
                          title="Delete the record"
                          description="Are you sure to delete this record?"
                          icon={
                            <QuestionCircleOutlined style={{ color: "red" }} />
                          }
                          onConfirm={() => {
                            patchPages({
                              variables: { input: get.matchId },
                            });
                            Toast.show({
                              icon: "loading",
                              content: "Deleting match...",
                            });
                          }}
                        >
                          <Button type="link" danger>
                            Delete
                          </Button>
                        </Popconfirm>
                        <br />
                        {get.properties.pw1.rollup.array[0].title[0].plain_text}
                        /
                        {get.properties.pw2.rollup.array[0].title[0].plain_text}
                        <Tag bordered={false} color="success">
                          winners
                        </Tag>
                        <Tag>VS</Tag>{" "}
                        {get.properties.pl1.rollup.array[0].title[0].plain_text}
                        /
                        {get.properties.pl2.rollup.array[0].title[0].plain_text}
                        <Tag bordered={false} color="error">
                          losers
                        </Tag>
                        <Divider />
                      </div>
                    );
                  })
                ) : (
                  <Empty
                    description="No matches, create one"
                    image={
                      <AppOutline
                        style={{
                          color: "var(--adm-color-light)",
                          fontSize: 48,
                        }}
                      />
                    }
                  /> // Show empty state if no matches
                )}
              </div>
              <Divider orientation="left">
                Today&apos;s Results <RiseOutlined />
              </Divider>
              <div className="m-2">
                <div className="ml-5 font-semibold text-primary-100">
                  Total: {totalPoints}
                </div>
                {todayPool && todayPool.length > 0 ? (
                  todayPool.map((res, key) => {
                    return (
                      <div
                        key={key}
                        className="flex items-center w-full py-2 border-b"
                        onClick={() => {
                          form2.setFieldsValue({
                            amount: -res.totalPoints,
                            player: res.id,
                            action: "6fdd8c12-dbfe-4374-b4b2-f111e1a5c808",
                          });
                          setOpen(!open);
                        }}
                      >
                        <span className="flex-shrink-0 text-left">
                          {res.name}
                          <Tag bordered={false}>
                            played {res.gamesPlayed}
                          </Tag>{" "}
                        </span>
                        <div className="flex-grow mx-2 border-b border-gray-300 border-dotted"></div>
                        <span className="flex-shrink-0 text-right">
                          {res.totalPoints}
                        </span>
                      </div>
                    );
                  })
                ) : (
                  <Empty
                    description="No results, create one"
                    image={
                      <AppOutline
                        style={{
                          color: "var(--adm-color-light)",
                          fontSize: 48,
                        }}
                      />
                    }
                  /> // Show empty state if no results
                )}

                <Divider />
              </div>
            </Spin>
          </div>
        </Skeleton>
      </div>

      <Modal
        centered
        footer={null}
        title={
          <span className="flex items-center">
            Insert Credit <DollarOutlined className="ml-1" />
          </span>
        }
        open={open}
        onOk={() => {
          setOpen(false);
        }}
        onCancel={() => {
          setOpen(false);
        }}
        okText="Submit"
      >
        <Form
          form={form2}
          layout="vertical"
          initialValues={{ todayDate: dayjs() }}
          onFinish={values => {
            // Find the selected action to determine if the amount should be negative
            const selectedAction = selectAction.find(
              action => action.value === values.action
            );

            // Adjust the amount based on isNeg property
            const adjustedAmount = selectedAction?.isNeg
              ? -Math.abs(values.amount)
              : Math.abs(values.amount);

            // Prepare the input for the mutation
            const input = {
              ...values,
              amount: adjustedAmount, // Use the adjusted amount
            };

            creditCreate({ variables: { input, addedBy: username } });
            setOpen(false);
            form2.resetFields();
            Toast.show({
              icon: "success",
              content: "Credit Added",
            });
          }}
        >
          <Form.Item
            label="Players"
            name="player"
            rules={[{ required: true, message: "Please input your player!" }]}
          >
            <Select
              showSearch
              options={select}
              loading={loadingMkbPlayers}
              disabled={loadingMkbPlayers}
            />
          </Form.Item>
          <Form.Item
            label="Action"
            name="action"
            rules={[{ required: true, message: "Please input your action!" }]}
          >
            <Select showSearch options={selectAction} />
          </Form.Item>
          <Form.Item
            label="Amount"
            name="amount"
            rules={[{ required: true, message: "Please input your Amount!" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item className="my-4" name="todayDate">
            <DatePicker />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

export default withMutatePatchPages(
  withMutateAddCredit(
    withMKBAction(withMutateAddMatch(withMKBPlayers(withMKBList(Home))))
  )
);
